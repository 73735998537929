<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">店家資料管理《店家》</li>
            <li class="breadcrumb-item active" aria-current="page">台中通特店區</li>
        </ol>
        </nav>
        <hr class="w-100">
        <div class="col-12 row mb-3 mx-auto">
            <div class="col-12 accordion" id="accordion_filter">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="heading_filter">
                        <button class="accordion-button bg-dark text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_filter" aria-expanded="false" aria-controls="collapse_filter">
                            搜尋條件
                        </button>
                    </h2>
                    <div id="collapse_filter" class="accordion-collapse show" aria-labelledby="heading_filter" data-bs-parent="#accordion_filter">
                        <div class="accordion-body col-12 row">
                            <div class="mb-3 col-lg-4">
                                <label for="store_code" class="form-label">店家編號</label>
                                <input type="text" class="form-control" id="store_code" v-model="display_query_data.store_code">
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="tax_id" class="form-label">統一編號</label>
                                <input type="text" class="form-control" id="tax_id" v-model="display_query_data.tax_id">
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="store_name" class="form-label">店家名稱</label>
                                <input type="text" class="form-control" id="store_name" v-model="display_query_data.store_name">
                            </div>
                        </div>
                        <div class="col-12 row text-end mx-auto mb-3">
                            <div>
                                <button class="btn m-2 btn-primary" @click="search()">搜尋</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 row mx-auto mb-3">
            <div class="table-responsive container-lg col-12">
                <table class="table table-striped table-hover text-center align-middle">
                    <thead class="table-dark">
                        <tr>
                            <th class="col-2">店家編號</th>
                            <th class="col-2">統一編號</th>
                            <th class="col-3">店家名稱</th>
                            <th class="col-1">狀態</th>
                            <th class="col-3">功能</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in records" :key="item">
                            <td>{{ item.store_code }}</td>
                            <td>{{ item.tax_id }}</td>
                            <td>{{ item.store_name }}</td>
                            <td>{{ item.store_status }}</td>
                            <td>
                                <span>
                                    <router-link :to="'/TcpassStoreEdit/'+ item.store_id">
                                        <button class="btn m-2 btn-primary">申請e指通特店</button>
                                    </router-link>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-12 row mx-auto mb-3">
            <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                    <li class="page-item" :class="{'disabled': current_page === 1}" v-bind:style="{'cursor': current_page === 1 ? null : 'pointer'}">
                        <a class="page-link" aria-label="<<"
                            @click.prevent="onChangePage(1)">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li class="page-item" :class="{'disabled': current_page === 1}" v-bind:style="{'cursor': current_page === 1 ? null : 'pointer'}">
                        <a class="page-link" aria-label="<"
                            @click.prevent="onChangePage(current_page - 1)">
                            <span aria-hidden="true">&lsaquo;</span>
                        </a>
                    </li>
                    <li class="page-item" v-for="page in links" :key="page" 
                        :class="{'active': page.active, 'disabled': page.label === '...'}" v-bind:style="{'cursor': page.active ? null : page.label === '...' ? null : 'pointer'}">
                        <a class="page-link" @click.prevent="onChangePage(page.label)">{{ page.label }}</a>
                    </li>
                    <li class="page-item" :class="{'disabled': current_page === last_page}" v-bind:style="{'cursor': current_page === last_page ? null : 'pointer'}">
                        <a class="page-link" aria-label=">"
                            @click.prevent="onChangePage(current_page + 1)">
                            <span aria-hidden="true">&rsaquo;</span>
                        </a>
                    </li>
                    <li class="page-item" :class="{'disabled': current_page === last_page}" v-bind:style="{'cursor': current_page === last_page ? null : 'pointer'}">
                        <a class="page-link" aria-label=">>"
                            @click.prevent="onChangePage(last_page)">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-12 row mx-auto mb-3">
            <div class="input-group justify-content-center">
                <span class="text-vertical-center">前往第</span>
                <input type="number" class="form-control m-2" style="flex: 0 0 auto; width: auto;" id="current_page" v-model="current_page" :min="1" :max="9999999999">
                <span class="text-vertical-center">頁</span>
                <div>  
                    <button class="btn m-2 btn-primary" @click.prevent="onChangePage(current_page)">前往</button>
                </div>
            </div>
        </div>
        <div class="mb-3"></div>
    </div>
</template>

<script>
import { get_tcpass_store_list } from '../../api/tcpass_store';
import { mapActions, mapGetters } from 'vuex';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            last_page: 1,
            current_page: 1,
            links: [{
                label: "1",
                active: true,
            }],
            records: [],
            display_query_data: {
                store_code: "",
                tax_id: "",
                store_name: "",
            },
            query_data: {
                store_code: "",
                tax_id: "",
                store_name: "",
            },
            page: 1,
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        search(page = 1, page_change = false) {
            if (page_change === false) {
                this.query_data = JSON.parse(JSON.stringify(this.display_query_data));
            }
            this.page = page;
            get_tcpass_store_list({page: page, query_data: this.query_data}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'Index' });
                } else {
                    if (this.page === 1
                        && this.query_data.store_code === ""
                        && this.query_data.tax_id === ""
                        && this.query_data.store_name === ""
                        && this.$route.fullPath === this.$route.path
                    ) {
                        this.records = response.data.response_data.data;
                        this.links = response.data.response_data.links;
                        this.current_page = response.data.response_data.current_page;
                        this.last_page = response.data.response_data.last_page;
                    } else {
                        this.$router.push({
                            name: 'TcpassStoreList', 
                            query: {
                                page: this.page,
                                store_code: this.query_data.store_code,
                                tax_id: this.query_data.tax_id,
                                store_name: this.query_data.store_name,
                            }
                        });
                        this.records = response.data.response_data.data;
                        this.links = response.data.response_data.links;
                        this.current_page = response.data.response_data.current_page;
                        this.last_page = response.data.response_data.last_page;
                    }  
                }
            })
        },
        onChangePage(page) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })
            if (page > this.last_page || page <= 0) {
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '頁碼錯誤，無指定頁碼',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            } else if (page == this.page) {
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '頁碼錯誤，已於指定頁碼',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            } else {
                this.search(page, true);
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        if (this.$route.query.page !== undefined) {
            if (this.$route.query.store_code !== undefined) {
                this.display_query_data.store_code = this.$route.query.store_code;
            }
            if (this.$route.query.tax_id !== undefined) {
                this.display_query_data.tax_id = this.$route.query.tax_id;
            }
            if (this.$route.query.store_name !== undefined) {
                this.display_query_data.store_name = this.$route.query.store_name;
            }
            if (this.$route.query.page == 1) {
                this.search(this.$route.query.page, false);
            } else {
                this.search(this.$route.query.page);
            }
        } else {
            this.search(this.page);
        }
    },
    computed: {
        ...mapGetters({
            role_list: 'role_list'
        }),
    }
};
</script>
